@charset "UTF-8";
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/*
    You can see a clear demo of use/forwards here...
    https://www.youtube.com/watch?v=CR-a8upNjJ0&t=286s
*/
/* -----------------------------
File Path
-------------------------------- */
/* -----------------------------
Spacing
-------------------------------- */
/* -----------------------------
style variables
-------------------------------- */
/* -----------------------------
Header
-------------------------------- */
/* -----------------------------
Page
-------------------------------- */
/*
    Breakpoint variables
*/
/*
    Variable Example
*/
/*
    Breakpoint Mixins
*/
/*
    Joint hover and Breakpoint Mixin
*/
/* ------------------------------------------------------
**** Fonts
------------------------------------------------------ */
/* ---- Webfonts ---- */
/*

Source Sans Pro
Google fonts
font-family: 'Source Sans Pro', sans-serif;

DIN Next
Fonts.com
font-family: 'DIN Next W01', sans-serif;

Neue Helvetica
Fonts.com
font-family: 'Neue Helvetica W01', sans-serif;

Montserrat
Field labels

*/
:root {
  --body-font-size: 1.5rem;
  --body-line-height: 1.5;
  --large-font-size: 1.6rem;
  --h1-font-size: 2rem;
  --h1-line-height: 1.2;
  --h2-font-size: 1.8rem;
  --h2-line-height: 1.2;
  --h3-font-size: 1.8rem;
  --h3-line-height: 1.2;
  --h4-font-size: 1.8rem;
  --h4-line-height: 1.2;
  --h5-font-size: 1.7rem;
  --h5-line-height: 1.2;
  --h6-font-size: 1.7rem;
  --h6-line-height: 1.2;
  /* [02] */
  --giga-font-size: 1.8rem;
  --mega-font-size: 4.4rem;
  --kilo-font-size: 4rem;
  --milli-font-size: 1.3rem;
  --centi-font-size: 1.4rem;
}

@media screen and (min-width: 22.5em) {
  :root {
    --h1-font-size: 2.2rem;
    --h2-font-size: 2rem;
    --h3-font-size: 1.9rem;
    --giga-font-size: 2.8rem;
  }
}
@media screen and (min-width: 39em) {
  :root {
    --giga-font-size: 4.3rem;
  }
}
@media screen and (min-width: 48em) {
  :root {
    --body-font-size: 1.6rem;
    --large-font-size: 1.8rem;
    --h1-font-size: 2.6rem;
    --h2-font-size: 2.2rem;
  }
}
@media screen and (min-width: 62em) {
  :root {
    --body-font-size: 1.8rem;
    --large-font-size: 2rem;
    --h1-font-size: 3rem;
    --h2-font-size: 2.8rem;
    --giga-font-size: 6.7rem;
  }
}
@media screen and (min-width: 75em) {
  :root {
    --h1-font-size: 4rem;
    --h2-font-size: 3.2rem;
    --h3-font-size: 1.9rem;
    --h4-font-size: 1.8rem;
    --h5-font-size: 1.7rem;
    --h6-font-size: 1.7rem;
    --giga-font-size: 7.3rem;
  }
}
/*
    Basic system font stacks
*/
/*
    Brand fonts
*/
/*
    Font stacks
*/
/*
    Font Family key for Fractal
*/
/* Weights */
/* Size */
/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */
/*
    Font Mixins
*/
/* ----- Brand colours ----- */
/* ------------------------------------------------------
**** Animations
------------------------------------------------------ */
/* ---- Mobile menu button ---- */
@-webkit-keyframes mobileMenuBtnTopClose {
  0% {
    -ms-transform: translate(0, 9px) rotate(45deg);
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
  50%, 70% {
    -ms-transform: translate(0, 9px);
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes mobileMenuBtnTopClose {
  0% {
    -ms-transform: translate(0, 9px) rotate(45deg);
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
  50%, 70% {
    -ms-transform: translate(0, 9px);
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes mobileMenuBtnTopOpen {
  0% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -ms-transform: translate(0, 9px);
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -ms-transform: translate(0, 9px) rotate(45deg);
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
}
@keyframes mobileMenuBtnTopOpen {
  0% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -ms-transform: translate(0, 9px);
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -ms-transform: translate(0, 9px) rotate(45deg);
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
}
@-webkit-keyframes mobileMenuBtnBottomClose {
  0% {
    -ms-transform: translate(0, -9px) rotate(-45deg);
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
  50%, 70% {
    -ms-transform: translate(0, -9px);
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes mobileMenuBtnBottomClose {
  0% {
    -ms-transform: translate(0, -9px) rotate(-45deg);
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
  50%, 70% {
    -ms-transform: translate(0, -9px);
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes mobileMenuBtnBottomOpen {
  0% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -ms-transform: translate(0, -9px);
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -ms-transform: translate(0, -9px) rotate(-45deg);
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
}
@keyframes mobileMenuBtnBottomOpen {
  0% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -ms-transform: translate(0, -9px);
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -ms-transform: translate(0, -9px) rotate(-45deg);
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
}
@-webkit-keyframes mobileMenuBtnMiddleClose {
  0%, 40% {
    opacity: 0;
  }
  50%, 100% {
    opacity: 1;
  }
}
@keyframes mobileMenuBtnMiddleClose {
  0%, 40% {
    opacity: 0;
  }
  50%, 100% {
    opacity: 1;
  }
}
@-webkit-keyframes mobileMenuBtnMiddleOpen {
  0%, 40% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}
@keyframes mobileMenuBtnMiddleOpen {
  0%, 40% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}
/* Fade in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* ---- Content Entry ---- */
/* Fade in down */
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}
/* Fade in up */
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* Fade in up margin */
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
/* Grow in fade */
@keyframes growInFade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
/* ---- Highlight pulse ---- */
@keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
/* ---- Highlight pulse with outline ---- */
@keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
* {
  margin: 0;
}

html,
body {
  background: #ffffff;
  height: 100%;
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
}

body {
  line-height: var(--body-line-height);
  font-size: var(--body-font-size);
  color: #4b4b4b;
  font-family: "Neue Helvetica W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@viewport {
  width: device-width;
  zoom: 1;
}
img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
table {
  border-collapse: collapse;
  border: 1px solid #D7D7D7;
  width: 100%;
}
.content-block table {
  margin-bottom: 24px;
}

th,
td {
  padding: 5px 10px;
  border: 1px solid #D7D7D7;
}

th,
thead td {
  background: #f6f6f6;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Content tables ---- */
.table--content-standard {
  width: 100%;
  margin: 2rem 0;
}

.table--content-standard caption {
  text-align: left;
  padding-bottom: 13px;
}

.table--content-standard th,
.table--content-standard thead td {
  font-weight: bold;
  background-color: #f6f6f6;
}

.table--content-standard td {
  background-color: #ffffff;
}

.table--content-standard th,
.table--content-standard td {
  border: 1px solid #D7D7D7;
  padding: 12px 15px;
  text-align: left;
}

/* Small */
.table--content-standard.table--content-small {
  font-size: 1.3rem;
}

.table--content-standard.table--content-small th,
.table--content-standard.table--content-small td {
  padding: 5px 10px;
}

.table--content-standard.table--content-small th,
.table--content-standard.table--content-small thead td {
  font-size: 1.4rem;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Responsive table ---- */
table.responsive {
  overflow: auto;
  overflow-y: hidden;
  overflow-x: auto;
  display: block;
}

.table--content-small {
  font-size: 1.3rem;
}

.table--content-small th,
.table--content-small td {
  padding: 5px 10px;
}

.table--content-small th,
.table--content-small thead td {
  font-size: 1.4rem;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.table--header-column th {
  text-align: left;
}

.table--row-striped {
  border: 1px solid transparent;
}
.table--row-striped th,
.table--row-striped td {
  border: 1px solid transparent;
}
.table--row-striped th,
.table--row-striped thead td {
  background: transparent;
}
.table--row-striped tr:nth-child(odd) td,
.table--row-striped tr:nth-child(odd) th {
  background-color: #ffffff;
}
.table--row-striped tr:nth-child(even):last-child td, .table--row-striped tr:nth-child(even):last-child th {
  border-bottom-color: #ffffff;
  border-bottom-width: 2px;
}
.table--row-striped tr:first-child td:first-child,
.table--row-striped tr:first-child th:first-child {
  border-top-left-radius: 2px;
}
.table--row-striped tr:first-child td:last-child,
.table--row-striped tr:first-child th:last-child {
  border-top-right-radius: 2px;
}
.table--row-striped tr:last-child td:first-child,
.table--row-striped tr:last-child th:first-child {
  border-bottom-left-radius: 2px;
}
.table--row-striped tr:last-child td:last-child,
.table--row-striped tr:last-child th:last-child {
  border-bottom-right-radius: 2px;
}

[data-hidden-by-rules=true] {
  display: none;
}

.table__no-styles {
  border-collapse: separate !important;
  border: none !important;
  background: none !important;
  min-width: 0 !important;
}

.table__no-styles th,
.table__no-styles thead td,
.table__no-styles td {
  padding: 0 !important;
  border: 0 !important;
  background: none !important;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 var(--paragraph-break);
  margin: 1.6rem 0 1.2rem;
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 100;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  margin-top: 3rem;
}

h1, .h1 {
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 100;
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
  text-transform: uppercase;
  margin: 0.75em 0 var(--large-heading-break) 0;
  color: #2c364a;
}

h2, .h2 {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 400;
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  margin: 0.66em 0 var(--large-heading-break);
  color: #0073BC;
}

h3, .h3 {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  color: #0073BC;
}

h4, .h4 {
  font-size: var(--h4-font-size);
  line-height: var(--h4-line-height);
  color: inherit;
}

h5, .h5 {
  font-size: var(--h5-font-size);
  line-height: var(--h5-line-height);
  color: inherit;
}

h6, .h6 {
  font-size: var(--h6-font-size);
  line-height: var(--h6-line-height);
  color: inherit;
}

p,
ol,
ul,
dl,
address {
  margin: 0 0 var(--body-font-size);
}

small {
  font-size: var(--milli-font-size);
}

.heading-inline {
  display: inline;
  float: left;
  padding: 0;
}

.heading-w-subtitle {
  margin-bottom: 0;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #EEE;
  margin: 2em 0;
  padding: 0;
  clear: right;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Text level elements ---- */
abbr[title] {
  border-bottom: 1px dotted #b8b8b8;
  cursor: help;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

ins {
  background-color: #f6f6f6;
  color: #4b4b4b;
  text-decoration: none;
}

mark {
  background-color: #f6f6f6;
  color: #4b4b4b;
  font-style: italic;
  font-weight: bold;
}

pre,
code,
kbd,
samp {
  font-family: Monaco, Courier New, monospace;
  color: #4b4b4b;
  background: #f6f6f6;
  background: rgba(0, 0, 0, 0.07);
  padding: 0 2px;
  margin: 0 -2px;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

small {
  font-size: 1.28rem;
}

:root {
  --paragraph-break: 1.5rem;
  --large-heading-break: 1rem;
}

@media screen and (min-width: 48em) {
  :root {
    --large-heading-break: 1.4rem;
  }
}
@media screen and (min-width: 62em) {
  :root {
    --large-heading-break: 1.7rem;
  }
}
@media screen and (min-width: 75em) {
  :root {
    --large-heading-break: 1.9rem;
  }
}
p,
address {
  margin: 0 0 var(--paragraph-break);
}

small {
  font-size: var(--milli-font-size);
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.reverse-text {
  color: #ffffff;
}
.reverse-text :where(a) {
  color: currentColor;
}
.reverse-text :where(a):hover,
.reverse-text :where(a):focus,
.reverse-text :where(a):active {
  color: rgba(255, 255, 255, 0.75);
}
.reverse-text :where(h1),
.reverse-text :where(h2),
.reverse-text :where(h3),
.reverse-text :where(h4) {
  color: #ffffff;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Text selection ---- */
::-moz-selection {
  color: #000;
  background: #FFC57D;
}

::selection {
  color: #000;
  background: #FFC57D;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Line title ---- */
.line-title {
  margin: 0 0 45px;
}

.line-title__title {
  position: relative;
}

.line-title__title:after {
  content: " ";
  display: block;
  height: 18px;
  margin-top: 18px;
}

.line-title__title h2,
.line-title__title .h2,
.line-title__title .line-title__title__copy {
  margin: 0;
  font-size: 2rem;
  color: #2c364a;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 100;
}

.line-title__link {
  float: right;
  margin-left: 50px;
}

/* Side */
.line-title.line-title--side .line-title__title {
  padding-bottom: 26px;
}

.line-title.line-title--side .line-title__title:after {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1000px;
  background-image: url(../images/texture-lines-lightblue.png);
}

.line-title.line-title--side .line-title__title {
  display: inline-block;
}

/* Dark blue */
.line-title.line-title--darkblue .line-title__title:after {
  background-image: url(../images/texture-lines-blue.png);
}

/* Green */
.line-title.line-title--green .line-title__title:after {
  background-image: url(../images/texture-lines-green.png);
}

/* BP 1 */
@media screen and (max-width: 1240px) {
  .line-title {
    margin-bottom: 27px;
  }
}
/* BP 3 */
@media screen and (max-width: 760px) {
  .line-title {
    margin-bottom: 20px;
  }
  .line-title__title:after {
    height: 12px;
    margin-top: 12px;
  }
  .line-title.line-title--side .line-title__title {
    padding-bottom: 18px;
  }
}
/* BP 4 */
@media screen and (max-width: 620px) {
  .line-title {
    margin-bottom: 15px;
  }
  .line-title__title:after {
    height: 8px;
    margin-top: 8px;
  }
  .line-title.line-title--side .line-title__title {
    padding-bottom: 12px;
  }
  .line-title__link {
    float: none;
    margin: 20px 0 10px;
  }
  .line-title.line-title--side .line-title__title {
    display: block;
  }
}
/* BP 3 */
@media screen and (max-width: 760px) {
  .inner-page-title .line-title__title {
    display: none;
  }
}
/* Green */
.line-title.line-title--green .line-title__title:after {
  background-image: url(../images/texture-lines-green.png);
}

/* BP 1 */
@media screen and (max-width: 1240px) {
  .line-title {
    margin-bottom: 27px;
  }
}
/* BP 3 */
@media screen and (max-width: 760px) {
  .line-title {
    margin-bottom: 20px;
  }
  .line-title__title:after {
    height: 12px;
    margin-top: 12px;
  }
}
/* BP 4 */
@media screen and (max-width: 620px) {
  .line-title {
    margin-bottom: 15px;
  }
  .line-title__title:after {
    height: 8px;
    margin-top: 8px;
  }
}
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
:root {
  --default-container: 90vw;
  --default-container-gutter: 5vw;
  --edge-container: var(--default-container);
  --edge-container-gutter: var(--default-container-gutter);
}

@media screen and (min-width: 35em) {
  :root {
    --default-container: 92vw;
    --default-container-gutter: 4vw;
    --edge-container: calc(100vw - 64px);
    --edge-container-gutter: 32px;
  }
}
@media screen and (min-width: 77.5em) {
  :root {
    --default-container: 1140px;
    --default-container-gutter: calc(50vw - (var(--default-container) / 2));
  }
}
@media screen and (min-width: 120em) {
  :root {
    --edge-container: 1856px;
    --edge-container-gutter: calc(50vw - (var(--edge-container) / 2));
  }
}
@media screen and (min-width: 150em) {
  :root {
    --default-container-gutter: calc((2400px - var(--default-container)) / 2);
    --edge-container-gutter: calc((2400px - var(--edge-container)) / 2);
  }
}
.container {
  width: var(--default-container);
  margin: 0 auto;
}

/* [01] The bleed utility classes now live in the background colours file */
/* ---- Home banner ---- */
.hero-banner {
  padding: 0;
  background-color: #2c364a;
  color: #ffffff;
}
.hero-banner :where(a) {
  color: currentColor;
}
.hero-banner :where(a):hover,
.hero-banner :where(a):focus,
.hero-banner :where(a):active {
  color: rgba(255, 255, 255, 0.75);
}
.hero-banner :where(h1),
.hero-banner :where(h2),
.hero-banner :where(h3),
.hero-banner :where(h4) {
  color: #ffffff;
}
@media screen and (min-width: 48em) {
  .hero-banner {
    width: var(--edge-container);
    margin-inline: auto;
    margin-block: var(--edge-container-gutter);
  }
}
@media screen and (min-width: 75em) {
  .hero-banner {
    margin-bottom: 8px;
  }
}
@media screen and (min-width: 120em) {
  .hero-banner {
    margin-top: 32px;
  }
}
.hero-banner:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../images/texture-dots.svg);
  opacity: 0.1;
  background-size: cover;
  z-index: 0;
}
@media screen and (max-width: 47.9375em) {
  .hero-banner .splide__arrow {
    top: calc(var(--edge-container) * 0.5 / 2 - (var(--h1-font-size) + 32px) / 2);
  }
}

.hero-banner__copy {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
  padding-inline: var(--default-container-gutter);
  padding-block: var(--section);
}
@media screen and (min-width: 48em) {
  .hero-banner__copy {
    padding-inline: var(--edge-container-gutter);
    padding-bottom: var(--edge-container-gutter);
    padding-top: 0;
    height: calc(var(--edge-container) * 0.5);
  }
}
@media screen and (min-width: 75em) {
  .hero-banner__copy {
    height: calc(var(--edge-container) * 0.4);
  }
}
@media screen and (min-width: 77.5em) {
  .hero-banner__copy {
    padding-inline: calc(var(--default-container-gutter) - var(--edge-container-gutter));
    padding-bottom: calc((var(--default-container-gutter) - var(--edge-container-gutter)) / 2);
  }
}

.hero-banner__heading {
  font-size: var(--giga-font-size);
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: 1;
  margin-top: 0;
}

.hero-banner__subtitle {
  font-size: var(--h2-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 100;
  text-transform: uppercase;
  line-height: 1;
}

.hero-banner__description {
  font-size: var(--body-font-size);
}
@media screen and (min-width: 48em) {
  .hero-banner__description {
    text-wrap: balance;
  }
}
@media screen and (min-width: 75em) {
  .hero-banner__description {
    max-width: calc(var(--default-container) * 0.75);
  }
}

.hero-banner__img {
  display: block;
  width: 100%;
  height: calc(var(--edge-container) * 0.5);
}
@media screen and (min-width: 48em) {
  .hero-banner__img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    height: auto;
  }
}
.hero-banner__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-banner__img:before, .hero-banner__img:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(40, 47, 64, 0.15), rgba(40, 47, 64, 0.9));
  background-size: cover;
}
.hero-banner__img:before {
  mix-blend-mode: multiply;
}
.hero-banner__img:after {
  background: linear-gradient(to bottom, rgba(40, 47, 64, 0.15), rgba(40, 47, 64, 0.5));
}

.hero-banner__button {
  display: inline-block;
  align-self: flex-start;
}

/* ---- Home about ---- */
.section--home-about__buttons {
  margin-top: 80px;
}

/* BP 1 */
@media screen and (max-width: 1240px) {
  .section--home-about__buttons {
    margin-top: 50px;
  }
}
/* BP 2 */
@media screen and (max-width: 1000px) {
  .section--home-about__buttons {
    margin-top: 30px;
  }
}
/* BP 3 */
@media screen and (max-width: 760px) {
  .section--home-about__buttons {
    margin-top: 20px;
  }
}
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
:root {
  --box-title-height: 72px;
}

.boxes {
  width: var(--edge-container);
  margin-inline: auto;
  margin-block: var(--edge-container-gutter);
}
@media screen and (min-width: 75em) {
  .boxes {
    margin-top: 8px;
  }
}

.boxes-list {
  display: grid;
  gap: var(--edge-container-gutter);
}
@media screen and (min-width: 35em) {
  .boxes-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 75em) {
  .boxes-list {
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
  }
}

.boxes__item {
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media screen and (hover: hover) and (min-width: 75em) {
  .boxes__item {
    position: relative;
    overflow: hidden;
  }
  .boxes__item:hover, .boxes__item:focus, .boxes__item:active {
    background-color: #eef4f7;
  }
  .boxes__item:hover .boxes__item__content, .boxes__item:focus .boxes__item__content, .boxes__item:active .boxes__item__content {
    top: var(--box-title-height);
  }
  .boxes__item:hover .boxes__item__title, .boxes__item:focus .boxes__item__title, .boxes__item:active .boxes__item__title {
    top: 0;
    bottom: auto;
    height: var(--box-title-height);
  }
  .boxes__item:hover .boxes__item__logo, .boxes__item:focus .boxes__item__logo, .boxes__item:active .boxes__item__logo {
    opacity: 0;
  }
  .boxes__item:hover .boxes__item__picture:before,
  .boxes__item:hover .boxes__item__picture:after, .boxes__item:focus .boxes__item__picture:before,
  .boxes__item:focus .boxes__item__picture:after, .boxes__item:active .boxes__item__picture:before,
  .boxes__item:active .boxes__item__picture:after {
    animation: fadeInWhiteBackground 250ms forwards;
    animation-delay: 125ms;
  }
  .boxes__item:hover .boxes__item__description, .boxes__item:focus .boxes__item__description, .boxes__item:active .boxes__item__description {
    background-color: #eef4f7;
    animation: fadeInDescription 250ms both;
    animation-delay: 125ms;
  }
}

.boxes__item__picture {
  display: none;
}
@media screen and (min-width: 75em) {
  .boxes__item__picture {
    position: relative;
    width: 100%;
    display: grid;
    place-content: center;
    aspect-ratio: 1;
    background-color: #eef4f7;
  }
}
.boxes__item__picture:before, .boxes__item__picture:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@media screen and (hover: hover) and (min-width: 75em) {
  .boxes__item__picture:before, .boxes__item__picture:after {
    transition: background-color 250ms ease-in-out;
  }
}
.boxes__item__picture:before {
  mix-blend-mode: soft-light;
  opacity: 0.8;
}
.boxes__item__picture:after {
  z-index: 1;
  mix-blend-mode: multiply;
  opacity: 0.8;
}
.boxes__item__picture .boxes__item__image {
  width: 100%;
}

.boxes__item__logo {
  width: 80px;
  height: 80px;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}
@media screen and (hover: hover) and (min-width: 75em) {
  .boxes__item__logo {
    top: calc(50% - var(--box-title-height) / 2 - 40px);
  }
}

.boxes__item__picture--fallback {
  padding-bottom: 100%;
}

.boxes__item__image--fallback {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url(../images/texture-dots.svg);
  opacity: 0.1;
  background-size: cover;
  z-index: 1;
}

.boxes__item__title {
  margin: 0;
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 100;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  line-height: 1.2;
  padding-block: var(--paragraph-break);
  background-color: #0073BC;
  color: #ffffff;
  transition: background-color 250ms ease-in-out, top 250ms ease-in-out, bottom 250ms ease-in-out;
}
@media screen and (hover: hover) and (min-width: 75em) {
  .boxes__item__title {
    position: absolute;
    top: calc(100% - var(--box-title-height));
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

.boxes__item__content {
  background: #eef4f7;
  color: #4b4b4b;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
@media screen and (hover: hover) and (min-width: 75em) {
  .boxes__item__content {
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: transparent;
    transition: background-color 250ms ease-in-out, top 250ms ease-in-out, bottom 250ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.boxes__item__description {
  padding-inline: var(--paragraph-break);
  padding-block: calc(var(--paragraph-break) * 2);
}
@media screen and (min-width: 35em) {
  .boxes__item__description {
    padding: 3vw;
  }
}
@media screen and (min-width: 75em) {
  .boxes__item__description {
    padding-inline: var(--paragraph-break);
    padding-block: calc(var(--paragraph-break) * 2);
  }
}
@media screen and (min-width: 87.5em) {
  .boxes__item__description {
    font-size: var(--h4-font-size);
  }
}
@media screen and (hover: hover) and (min-width: 75em) {
  .boxes__item__description {
    flex: 1 1 auto;
    line-height: 1.2;
    padding: var(--paragraph-break);
    display: grid;
    place-items: center;
    opacity: 0;
    transition: none;
  }
}
.boxes__item__description p:last-child {
  margin-bottom: 0;
}

.boxes__item__button {
  margin-top: auto;
}
@media screen and (hover: hover) and (min-width: 75em) {
  .boxes__item__button {
    height: var(--box-title-height);
    margin-top: 0;
    flex: 0 0 auto;
  }
}

/* Fade in up */
@keyframes fadeInWhiteBackground {
  to {
    background-color: #eef4f7;
  }
}
/* Fade in up */
@keyframes fadeOutDescription {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeInDescription {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.date-object__date {
  background-color: #0073BC;
  color: #ffffff;
  max-width: 80px;
  text-align: center;
  font-family: "DIN Next W01", sans-serif;
}

.date-object__date__month {
  background-color: #282f40;
  padding: 3px 10px 0;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.date-object__date__day {
  padding: 6px 10px 3px;
  font-size: 3.4rem;
  line-height: 3.4rem;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Icon circle list ---- */
.icon-circle-list {
  list-style: none;
  margin: 25px 0;
  padding: 0;
  line-height: 1.5;
}

.icon-circle-list__item {
  margin-bottom: 12px;
}

.icon-circle-list a,
.icon-circle-list span.icon-before {
  text-decoration: none;
  color: inherit;
  position: relative;
  white-space: nowrap;
}

.icon-circle-list__item a:before,
.icon-circle-list__item span.icon-before:before,
.icon-circle:before {
  background-color: #0073BC;
  border-radius: 50%;
  display: block;
  text-align: center;
  color: #FFF;
  display: inline-block;
  vertical-align: top;
  height: 24px;
  width: 24px;
  font-size: 21px;
  line-height: 1.1;
}
@media screen and (min-width: 48em) {
  .icon-circle-list__item a:before,
  .icon-circle-list__item span.icon-before:before,
  .icon-circle:before {
    height: 28px;
    width: 28px;
    font-size: 22px;
    line-height: 1.3;
  }
}
@media screen and (min-width: 62em) {
  .icon-circle-list__item a:before,
  .icon-circle-list__item span.icon-before:before,
  .icon-circle:before {
    height: 40px;
    width: 40px;
    line-height: 1.5;
    font-size: 28px;
  }
}

/* With text */
.icon-circle-list__item--text {
  display: flex;
  align-items: center;
}

.icon-circle-list__item--text a:before,
.icon-circle-list__item--text span.icon-before:before {
  margin-top: 0;
  margin-right: 10px;
}
@media screen and (min-width: 39em) {
  .icon-circle-list__item--text a:before,
  .icon-circle-list__item--text span.icon-before:before {
    margin-top: -4px;
    margin-right: 9px;
  }
}
@media screen and (min-width: 48em) {
  .icon-circle-list__item--text a:before,
  .icon-circle-list__item--text span.icon-before:before {
    margin-right: 8px;
  }
}
@media screen and (min-width: 62em) {
  .icon-circle-list__item--text a:before,
  .icon-circle-list__item--text span.icon-before:before {
    margin-right: 9px;
  }
}
@media screen and (min-width: 75em) {
  .icon-circle-list__item--text a:before,
  .icon-circle-list__item--text span.icon-before:before {
    margin-right: 13px;
  }
}

/* -- Inline -- */
.icon-circle-list--inline {
  margin: 0;
  line-height: 1.5;
}
@media screen and (min-width: 75em) {
  .icon-circle-list--inline {
    line-height: 1.2;
  }
}
.icon-circle-list--inline .icon-circle-list__item {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2px;
  line-height: 1.6;
}
@media screen and (min-width: 62em) {
  .icon-circle-list--inline .icon-circle-list__item {
    margin-right: 5px;
    line-height: inherit;
  }
}
.icon-circle-list--inline .icon-circle-list__item--text a:before,
.icon-circle-list--inline .icon-circle-list__item--text span.icon-before:before {
  margin-top: 0;
}
.icon-circle-list--inline .icon-circle-list__item:last-child {
  margin-right: 0;
}
.icon-circle-list--inline.icon-circle-list--large {
  line-height: 1.6;
}
@media screen and (min-width: 75em) {
  .icon-circle-list--inline.icon-circle-list--large {
    line-height: 1.9;
  }
}

/* Lightblue */
.icon-circle-list__item.icon-circle-list__item--lightblue a:before,
.icon-circle-list__item.icon-circle-list__item--lightblue span.icon-before:before,
.icon-circle--lightblue:before {
  background-color: #445374;
  color: #2c364a;
}

.icon-circle-list__item.icon-circle-list__item--lightblue a:hover:before {
  background-color: #556891;
}

.icon-circle-list__item.icon-circle-list__item--lightblue-white a:before,
.icon-circle-list__item.icon-circle-list__item--lightblue-white span.icon-before:before,
.icon-circle--lightblue:before {
  background-color: #445374;
  color: #ffffff;
}

.icon-circle-list__item.icon-circle-list__item--lightblue-white a:hover:before {
  background-color: #556891;
}

/* White */
.icon-circle-list__item.icon-circle-list__item--white a:before,
.icon-circle-list__item.icon-circle-list__item--white span.icon-before:before,
.icon-circle--white:before {
  background-color: #FFF;
  color: #2c364a;
}

.icon-circle-list__item.icon-circle-list__item--white a,
.icon-circle-list__item.icon-circle-list__item--white span.icon-before,
.icon-circle--white {
  color: #FFF;
}

.icon-circle-list__item.icon-circle-list__item--white a:hover:before {
  color: #566582;
}

/* White green */
.icon-circle-list__item.icon-circle-list__item--whitegreen a:before,
.icon-circle-list__item.icon-circle-list__item--whitegreen span.icon-before:before,
.icon-circle--whitegreen:before {
  background-color: #FFF;
  color: #61b258;
}

.icon-circle-list__item.icon-circle-list__item--whitegreen a,
.icon-circle-list__item.icon-circle-list__item--whitegreen span.icon-before,
.icon-circle--whitegreen {
  color: #FFF;
}

.icon-circle-list__item.icon-circle-list__item--whitegreen a:hover:before {
  color: #7aca71;
}

/* Small */
.icon-circle-list--small a:before,
.icon-circle-list--small span.icon-before:before,
.icon-circle--small:before {
  height: 30px;
  width: 30px;
  font-size: 22px;
  line-height: 1.3;
}

@media screen and (min-width: 75em) {
  /* Large until BP2, then regular */
  .icon-circle-list--large,
  .icon-circle--large {
    font-size: 2.7rem;
    line-height: 2.2;
  }
  .icon-circle-list--large a:before,
  .icon-circle-list--large span.icon-before:before,
  .icon-circle--large:before {
    height: 56px;
    width: 56px;
    font-size: 37px;
    line-height: 1.5;
  }
  .icon-circle-list--large.icon-circle-list--inline .icon-circle-list__item {
    margin-right: 17px;
  }
}
/* ---- Icomoon ---- */
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon/fonts/icomoon.eot?vyb8er");
  src: url("../fonts/icomoon/fonts/icomoon.eot?vyb8er#iefix") format("embedded-opentype"), url("../fonts/icomoon/fonts/icomoon.ttf?vyb8er") format("truetype"), url("../fonts/icomoon/fonts/icomoon.woff?vyb8er") format("woff"), url("../fonts/icomoon/fonts/icomoon.svg?vyb8er#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.icon-before:before,
.icon-after:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.icon-before.icon-plus:before,
.icon-after.icon-plus:after {
  content: "\e917";
}

.icon-before.icon-minus:before,
.icon-after.icon-minus:after {
  content: "\e916";
}

.icon-before.icon-arrow-down:before,
.icon-after.icon-arrow-down:after {
  content: "\e900";
}

.icon-before.icon-arrow-left:before,
.icon-after.icon-arrow-left:after {
  content: "\e901";
}

.icon-before.icon-arrow-right:before,
.icon-after.icon-arrow-right:after {
  content: "\e902";
}

.icon-before.icon-arrow-up:before,
.icon-after.icon-arrow-up:after {
  content: "\e903";
}

.icon-before.icon-check:before,
.icon-after.icon-check:after {
  content: "\e904";
}

.icon-before.icon-cloud-services:before,
.icon-after.icon-cloud-services:after {
  content: "\e905";
}

.icon-before.icon-double-arrows-down:before,
.icon-after.icon-double-arrows-down:after {
  content: "\e906";
}

.icon-before.icon-double-arrows-left:before,
.icon-after.icon-double-arrows-left:after {
  content: "\e907";
}

.icon-before.icon-double-arrows-right:before,
.icon-after.icon-double-arrows-right:after {
  content: "\e908";
}

.icon-before.icon-double-arrows-up:before,
.icon-after.icon-double-arrows-up:after {
  content: "\e909";
}

.icon-before.icon-email-address:before,
.icon-after.icon-email-address:after {
  content: "\e90a";
}

.icon-before.icon-email:before,
.icon-after.icon-email:after {
  content: "\e90b";
}

.icon-before.icon-facebook:before,
.icon-after.icon-facebook:after {
  content: "\e90c";
}

.icon-before.icon-home:before,
.icon-after.icon-home:after {
  content: "\e90d";
}

.icon-before.icon-integration:before,
.icon-after.icon-integration:after {
  content: "\e90e";
}

.icon-before.icon-linked-in:before,
.icon-after.icon-linked-in:after {
  content: "\e90f";
}

.icon-before.icon-logitech-logo:before,
.icon-after.icon-logitech-logo:after {
  content: "\e910";
}

.icon-before.icon-map-marker:before,
.icon-after.icon-map-marker:after {
  content: "\e911";
}

.icon-before.icon-phone:before,
.icon-after.icon-phone:after {
  content: "\e912";
}

.icon-before.icon-rocket:before,
.icon-after.icon-rocket:after {
  content: "\e913";
}

.icon-before.icon-timer-24hr:before,
.icon-after.icon-timer-24hr:after {
  content: "\e914";
}

.icon-before.icon-work-apps:before,
.icon-after.icon-work-apps:after {
  content: "\e915";
}

.panel {
  /* ---- ULs icon circle styles ---- */
}
.panel div:where(.icon-circle-list) ul {
  list-style: none;
  margin: 25px 0;
  padding: 0;
  line-height: 1.5;
  margin-top: 0;
}
.panel div:where(.icon-circle-list) li {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  align-items: center;
  justify-content: start;
  line-height: 1.15;
  margin-bottom: 12px;
}
.panel div:where(.icon-circle-list) li:before {
  text-decoration: none;
  color: inherit;
  position: relative;
  white-space: nowrap;
}
.panel div:where(.icon-circle-list) li:before {
  content: "\e904";
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  background-color: #0073BC;
  border-radius: 50%;
  display: block;
  text-align: center;
  color: #FFF;
  display: inline-block;
  vertical-align: top;
  height: 24px;
  width: 24px;
  font-size: 21px;
  line-height: 1.1;
}
@media screen and (min-width: 48em) {
  .panel div:where(.icon-circle-list) li:before {
    height: 28px;
    width: 28px;
    font-size: 22px;
    line-height: 1.3;
  }
}
@media screen and (min-width: 62em) {
  .panel div:where(.icon-circle-list) li:before {
    height: 40px;
    width: 40px;
    line-height: 1.5;
    font-size: 28px;
  }
}
@media screen and (min-width: 62em) {
  .panel div:where(.icon-circle-list) li:before {
    height: 28px;
    width: 28px;
    font-size: 22px;
    line-height: 1.3;
  }
}

@media screen and (min-width: 62em) {
  .panel {
    font-size: 1.6rem;
  }
}
.panel__container {
  display: grid;
}
@media screen and (min-width: 62em) {
  .panel__container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: [header] auto [content] 1fr [ctas] auto [end];
    column-gap: calc(var(--section) / 2);
  }
}

.panel__header {
  margin-bottom: calc(var(--section) / 2);
}
@media screen and (min-width: 62em) {
  .panel__header {
    grid-column: span 2;
  }
}
.panel__header > *:last-child {
  margin-bottom: 0;
}

.panel__title {
  margin-bottom: 0;
  text-align: center;
}

.panel__tagline {
  margin-top: 0;
  text-align: center;
}

.panel__introduction {
  line-height: 1.2;
  margin-bottom: 25px;
  font-family: "Source Sans Pro";
  font-size: var(--large-font-size);
  color: #2c364a;
  text-align: center;
}

@media screen and (min-width: 62em) {
  .panel--media-right .panel__copy {
    grid-column: 1;
    grid-row: content;
  }
}
@media screen and (min-width: 62em) {
  .panel--media-left .panel__copy {
    grid-column: 2;
    grid-row: content;
  }
}
@media screen and (min-width: 62em) {
  .panel__copy.full-width {
    grid-column: 1/3;
    text-align: center;
  }
}
.panel__copy > *:first-child {
  margin-top: 0;
}

.panel__media {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}
@media screen and (min-width: 62em) {
  .panel__media {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 62em) {
  .panel--media-right .panel__media {
    grid-column: 2;
    grid-row-start: content;
  }
}
@media screen and (min-width: 62em) {
  .panel--media-left .panel__media {
    grid-column: 1;
    grid-row-start: content;
  }
}

.panel__image {
  width: 100%;
}
.panel__image img {
  width: 100%;
}

.panel__video {
  display: block;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.panel__ctas {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}
@media screen and (min-width: 48em) {
  .panel__ctas {
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 1em;
  }
}
@media screen and (min-width: 62em) {
  .panel__ctas {
    margin-top: 25px;
  }
}
@media screen and (min-width: 62em) {
  .panel__ctas {
    grid-column: span 2;
  }
}
.panel__ctas a {
  flex: 0 1 auto;
}
@media screen and (min-width: 35em) {
  .panel__ctas a {
    min-width: max(200px, 30%);
  }
}
.panel__ctas a:last-child {
  margin-bottom: 0;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Article grid  ---- */
.post-gallery {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.post-item {
  position: relative;
  margin-bottom: 1.8rem;
  background-color: #ffffff;
}
@media screen and (min-width: 35em) {
  .post-item {
    margin-bottom: 2.4rem;
  }
}
@media screen and (min-width: 62em) {
  .post-item {
    width: calc(50% - 18px);
    margin-left: 17px;
    float: right;
  }
}
.post-item__date {
  position: absolute;
  top: calc(var(--default-container) * 0.6666666667);
  left: 0;
  transform: translateY(-100%);
}
@media screen and (min-width: 35em) {
  .post-item__date {
    z-index: 1;
    top: auto;
    bottom: 0;
    transform: none;
  }
}
.post-item__date.post-without-image {
  display: none;
}
@media screen and (min-width: 35em) {
  .post-item__date.post-without-image {
    display: inherit;
  }
}

.post-item__content {
  padding-inline: 1.25em;
  padding-block: 1.25em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 35em) {
  .post-item__content {
    flex: 1 1 auto;
  }
}
@media screen and (min-width: 48em) {
  .post-item__content {
    padding: 36px;
  }
}
@media screen and (min-width: 62em) {
  .post-item__content {
    padding: 24px;
  }
}

.post-item__type {
  margin: 0 0 0.2em;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 100;
  color: rgba(44, 54, 74, 0.75);
  font-size: var(--centi-font-size);
}
@media screen and (min-width: 62em) {
  .post-item__type {
    font-size: 1.6rem;
  }
}

.post-item__type-item:after {
  content: " • ";
}
.post-item__type-item:last-child:after {
  content: none;
}

.post-item__title {
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  font-size: var(--large-font-size);
  line-height: 1.15;
  color: #2c364a;
  margin-top: 0;
  margin-bottom: calc(var(--paragraph-break) * 0.5);
}
@media screen and (min-width: 35em) {
  .post-item__title {
    font-size: var(--h3-font-size);
  }
}
@media screen and (min-width: 62em) {
  .post-item__title {
    font-size: var(--large-font-size);
    margin-bottom: 0;
  }
}

.post-item__copy {
  font-size: var(--body-font-size);
  line-height: 1.2;
}
@media screen and (min-width: 62em) {
  .post-item__copy {
    display: none;
  }
}
.post-item__copy p:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 35em) {
  .post-item__preview {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
  }
}
@media screen and (min-width: 62em) {
  .post-item__preview {
    height: 100%;
  }
}

.post-item__img {
  display: block;
  background-color: #EEE;
  position: relative;
}
.post-item__img:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../images/texture-dots.svg);
  opacity: 0.1;
  background-size: cover;
  z-index: 0;
}
@media screen and (min-width: 35em) {
  .post-item__img {
    flex: 1 1 16.66%;
    max-width: 16.66%;
    padding: 16.66%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.post-item__img img {
  width: 100%;
}
@media screen and (min-width: 35em) {
  .post-item__img img {
    display: none;
  }
}

@media screen and (min-width: 35em) {
  .post-item__content-wrapper {
    flex: 1 1 auto;
    max-width: calc(83.34% - 21px);
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 62em) {
  .post-item__content-wrapper {
    flex-basis: 72%;
    max-width: 72%;
  }
}

@media screen and (min-width: 35em) {
  .post-item__hover {
    margin-top: auto;
  }
}

.post-item__cta {
  margin-bottom: 0;
}
.post-item__cta .button--overlay {
  display: block;
  width: 100%;
}

@media screen and (min-width: 62em) {
  .post-item--0 {
    width: calc(50% - 18px);
    margin-right: 17px;
    margin-left: 0;
    float: left;
  }
}
@media screen and (min-width: 35em) {
  .post-item--0 .post-item__date {
    bottom: auto;
    top: calc(var(--default-container) * 0.6666666667);
    left: 0;
    transform: translateY(-100%);
  }
}
@media screen and (min-width: 62em) {
  .post-item--0 .post-item__date {
    top: calc((var(--default-container) / 2 - 18px) * 0.6666666667);
  }
}
@media screen and (min-width: 35em) {
  .post-item--0 .post-item__preview {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 62em) {
  .post-item--0 .post-item__preview {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 35em) {
  .post-item--0 .post-item__content-wrapper {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 62em) {
  .post-item--0 .post-item__content-wrapper {
    width: 100%;
    float: unset;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 35em) {
  .post-item--0 .post-item__img {
    width: 100%;
    padding: 33.33%;
    float: none;
    flex: 1 1 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 62em) {
  .post-item--0 .post-item__img {
    padding: 0;
    max-width: 100%;
    margin-right: 0;
    aspect-ratio: initial;
    flex: 0 1 auto;
    align-self: start;
  }
}
@media screen and (min-width: 62em) {
  .post-item--0 .post-item__img img {
    aspect-ratio: 3/2;
    display: block;
  }
}
@media screen and (min-width: 35em) {
  .post-item--0 .post-item__content {
    padding: 36px;
  }
}
@media screen and (min-width: 62em) {
  .post-item--0 .post-item__content {
    padding-inline: 36px;
    padding-block: 24px;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 75em) {
  .post-item--0 .post-item__content {
    padding-block: 36px;
  }
}
@media screen and (min-width: 35em) {
  .post-item--0 .post-item__title {
    margin-bottom: 0.3rem;
    font-size: max(var(--h3-font-size), var(--large-font-size));
  }
}
@media screen and (min-width: 62em) {
  .post-item--0 .post-item__title {
    margin-bottom: calc(var(--paragraph-break) * 0.5);
  }
}
@media screen and (min-width: 35em) {
  .post-item--0 .post-item__copy {
    display: block;
  }
}
@media screen and (min-width: 62em) {
  .post-item--0 .post-item__copy {
    display: block;
  }
}
@media screen and (min-width: 35em) {
  .post-item--0 .post-item__hover {
    margin-top: 0;
  }
}
@media screen and (min-width: 75em) {
  .post-item--0 .post-item__cta {
    flex-basis: 100%;
    max-width: 100%;
  }
}

/* BP medium */
@media only screen and (min-width: 62em) { /* 992px */
  @supports (display: grid) {
    .post-gallery {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-row-gap: 36px;
      grid-column-gap: 36px;
    }
    .post-gallery::before,
    .post-gallery::after {
      content: none;
    }
    .post-item--0 {
      grid-column: 1;
      grid-row: 1/4;
    }
    .post-item--1 {
      grid-column: 2;
      grid-row: 1/2;
    }
    .post-item--2 {
      grid-column: 2;
      grid-row: 2/3;
    }
    .post-item--3 {
      grid-column: 2;
      grid-row: 3/4;
    }
    .post-item {
      width: 100%;
      margin: 0;
    }
    .post-item--0 {
      display: flex;
      flex-flow: column;
    }
    .post-item__hover {
      margin-top: auto;
    }
  }
}