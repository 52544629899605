@use "../../00-settings" as *;
@use "../../01-base/typography/reverse-text-mixins" as *;
@use "../../02-components/container/_container" as *;

/* ---- Article grid  ---- */
.post-gallery {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.post-item {
    position: relative;
    margin-bottom: 1.8rem;
    background-color: $color-white;

    @include screen($bp560) {
        margin-bottom: 2.4rem;
    }

    @include screen($bp992) {
        width: calc(50% - 18px);
        margin-left: 17px;
        float: right;
    }

    &:hover,
    &:focus,
    &:active {
        @mixin hover-enabled($bp1200) {
            .post-item__hover {
                opacity: 1;
            }
        }
    }
}

.post-item__date {
    position: absolute;
    top: calc(var(--default-container) * 0.6666666667);
    left: 0;
    transform: translateY(-100%);

    @include screen($bp560) {
        z-index: 1;
        top: auto;
        bottom: 0;
        transform: none;
    }

    &.post-without-image {
        display: none;

        @include screen($bp560) {
            display: inherit;
        }
    }
}

.post-item__content {
    padding-inline: 1.25em;
    padding-block: 1.25em;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include screen($bp560) {
        flex: 1 1 auto;
    }

    @include screen($bp768) {
        padding: 36px;
    }

    @include screen($bp992) {
        padding: 24px;
    }

    @mixin hover-enabled($bp1200) {
        padding: 0 36px;
        padding-bottom: 36px;
    }
}

.post-item__type {
    margin: 0 0 0.2em;
	text-transform: uppercase;
	line-height: 1.2;
	@include font-set('accent');
    color: rgba($color-00, 0.75);
    font-size: var(--centi-font-size);

    @include screen($bp992) {
        font-size: 1.6rem;
    }
}

.post-item__type-item {
    &:after {
        content: " • ";
    }

    &:last-child:after {
        content: none;
    }
}

.post-item__title {
    @include font-set('accent-bold');
    font-size: var(--large-font-size);
    line-height: 1.15;
    color: $color-00;
    margin-top: 0;
    margin-bottom: calc(var(--paragraph-break) * 0.5);

    @include screen($bp560) {
        font-size: var(--h3-font-size);
    }

    @include screen($bp992) {
        font-size: var(--large-font-size);
        margin-bottom: 0;
    }
}

.post-item__copy {
    font-size: var(--body-font-size);
    line-height: 1.2;

    @include screen($bp992) {
        display: none;
    }

    p:last-child {
        margin-bottom: 0;
    }
}


.post-item__preview {
    @include screen($bp560) {
        box-sizing: border-box;
        display: flex;
        flex: 0 1 auto;
    }

    @include screen($bp992) {
        height: 100%;
    }
}


.post-item__img {
    display: block;
    background-color: $color-grey-02;
    position: relative;

    &:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(../images/texture-dots.svg);
        opacity: 0.1;
        background-size: cover;
		z-index: 0;
    }

    @include screen($bp560) {
        flex: 1 1 16.66%;
        max-width: 16.66%;
        padding: 16.66%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    @mixin hover-enabled($bp1200) {
        width: 14.5%;
        padding: 14.5%;
        margin-right: 0;
        flex-basis: 20%;
        max-width: 20%;
    }

    img {
        width: 100%;

        @include screen($bp560) {
            display: none;
        }
    }
}


.post-item__content-wrapper {
    @include screen($bp560) {
        flex: 1 1 auto;
        max-width: calc(100% - 16.66% - 21px);

        display: flex;
        flex-direction: column;
    }

    @include screen($bp992) {
        flex-basis: 72%;
        max-width: 72%;
    }

    @mixin hover-enabled($bp1200) {
        position: relative;
    }
}

.post-item__hover {
    @include screen($bp560) {
        margin-top: auto;
    }

    @mixin hover-enabled($bp1200) {
        .post-item__hover {
            opacity: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(2, 130, 147, 0.9);

            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;

        }
    }
}

.post-item__cta {
    margin-bottom: 0;

    @mixin hover-enabled($bp1200) {
        flex-basis: 100%;
        max-width: 100%;
        padding: 45px;
    }

    .button--overlay {
        display: block;
        width: 100%;
    }
}


.post-item--0 {
    @include screen($bp992) {
        width: calc(50% - 18px);
        margin-right: 17px;
        margin-left: 0;
        float: left;
    }

    .post-item__date {
        @include screen($bp560) {
            bottom: auto;
            top: calc(var(--default-container) * 0.6666666667);
            left: 0;
            transform: translateY(-100%);
        }

        @include screen($bp992) {
            top: calc(((var(--default-container) / 2) - 18px) * 0.6666666667);
        }
    }

    .post-item__preview {
        @include screen($bp560) {
            flex-wrap: wrap;
        }

        @include screen($bp992) {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }

    .post-item__content-wrapper {
        @include screen($bp560) {
            flex: 1 1 100%;
            max-width: 100%;
        }

        @include screen($bp992) {
            width: 100%;
            float: unset;
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    .post-item__img {
        @include screen($bp560) {
            width: 100%;
            padding: 33.33%;
            float: none;
            flex: 1 1 100%;
            max-width: 100%;
        }

        @include screen($bp992) {
            padding: 0;
            max-width: 100%;
            margin-right: 0;
            aspect-ratio: initial;
            flex: 0 1 auto;
            align-self: start;
        }

        img {
            @include screen($bp992) {
                aspect-ratio: 3/2;
                display: block;
            }
        }
    }

    .post-item__content {
        @include screen($bp560) {
            padding: 36px;
        }

        @include screen($bp992) {
            padding-inline: 36px;
            padding-block: 24px;
            justify-content: flex-start;
        }

        @include screen($bp1200) {
            padding-block: 36px;
        }
    }

    .post-item__title {
        @include screen($bp560) {
            margin-bottom: 0.3rem;
            font-size: max(var(--h3-font-size), var(--large-font-size));
        }

        @include screen($bp992) {
            margin-bottom: calc(var(--paragraph-break) * 0.5);
        }
    }

    .post-item__copy {
        @include screen($bp560) {
            display: block;
        }

        @include screen($bp992) {
            display: block;
        }
    }

    .post-item__hover {
        @include screen($bp560) {
            margin-top: 0;
        }
    }

    .post-item__cta {
        @include screen($bp1200) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}

/* BP medium */
@media only screen and (min-width: 62em) { /* 992px */
    @supports(display: grid) {
        .post-gallery {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            grid-row-gap: 36px;
            grid-column-gap: 36px;
        }

        .post-gallery::before,
        .post-gallery::after {
            content: none;
        }

        .post-item--0 {
            grid-column: 1;
            grid-row: 1 / 4;
        }

        .post-item--1 {
            grid-column: 2;
            grid-row: 1 / 2;
        }

        .post-item--2 {
            grid-column: 2;
            grid-row: 2 / 3;
        }

        .post-item--3 {
            grid-column: 2;
            grid-row: 3 / 4;
        }

        .post-item {
            width: 100%;
            margin: 0;
        }

        .post-item--0 {
            display: flex;
            flex-flow: column;
        }

        .post-item__hover {
            margin-top: auto;
        }
    }
}
