@use "../../00-settings" as *;
@use "../../01-base/typography/reverse-text-mixins" as *;
@use "../../02-components/container/_container" as *;

:root {
	--box-title-height: 72px;
}

.boxes {
	width: var(--edge-container);
	margin-inline: auto;
	margin-block: var(--edge-container-gutter);

	@include screen($bp1200) {
		margin-top: $spacing;
	}
}

.boxes-list {
	display: grid;
	gap: var(--edge-container-gutter);

	@include screen($bp560) {
		grid-template-columns: repeat(2, 1fr);
	}

	@include screen($bp1200) {
		grid-template-columns: repeat(4, 1fr);
		gap: $spacing;
	}
}

.boxes__item {
	display: flex;
	flex-direction: column;
	text-align: center;

	@include hover-enabled($bp1200) {
		position: relative;
		overflow: hidden;

		&:hover,
		&:focus,
		&:active {
			background-color: $color-lightblue;

			.boxes__item__content {
				top: var(--box-title-height);
			}

			.boxes__item__title {
				top: 0;
				bottom: auto;
				height: var(--box-title-height);
			}

			.boxes__item__logo {
				opacity: 0;
			}

			.boxes__item__picture:before,
			.boxes__item__picture:after {
				animation: fadeInWhiteBackground $link-transition-duration forwards;
				animation-delay: calc($link-transition-duration / 2);
			}

			.boxes__item__description {
				background-color: $color-lightblue;
				animation: fadeInDescription $link-transition-duration both;
				animation-delay: calc($link-transition-duration / 2);
			}
		}
	}
}

.boxes__item__picture {
	display: none;
	// the images are do big on mobile and tablet
	// and they don't add anything

	@include screen($bp1200) {
		position: relative;
		width: 100%;
		display: grid;
		place-content: center;
		aspect-ratio: 1;
		background-color: $color-lightblue;
	}

	&:before,
	&:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

		@include hover-enabled($bp1200) {
			transition: background-color $link-transition;
		}
	}

	&:before {
		mix-blend-mode: soft-light;
		opacity: 0.8;
	}

	&:after {
		z-index: 1;
		mix-blend-mode: multiply;
		opacity: 0.8;
	}


	.boxes__item__image {
		width: 100%;
	}
}

.boxes__item__logo {
	width: 80px;
	height: 80px;
	position: absolute;
	top: calc(50% - 40px);
	left: calc(50% - 40px);
	opacity: 1;
	transition: opacity $link-transition;

	@include hover-enabled($bp1200) {
		top: calc(50% - (var(--box-title-height) / 2) - 40px);
	}
}

.boxes__item__picture--fallback {
	padding-bottom: 100%;
}

.boxes__item__image--fallback {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-image: url(../images/texture-dots.svg);
	opacity: 0.1;
	background-size: cover;
	z-index: 1;
}

.boxes__item__title {
	margin: 0;
	@include font-set('accent');
	text-transform: uppercase;
	font-size: var(--h1-font-size);
	line-height: 1.2;
	padding-block: var(--paragraph-break);
	background-color: $color-01;
	color: $color-white;
	transition:
		background-color $link-transition,
		top $link-transition,
		bottom $link-transition;

	@include hover-enabled($bp1200) {
		position: absolute;
		top: calc(100% - var(--box-title-height));
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
	}
}

.boxes__item__content {
	background: $color-lightblue;
	color: $color-grey-09;
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@include hover-enabled($bp1200) {
		position: absolute;
		top: 100%;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		background-color: transparent;
		transition:
			background-color $link-transition,
			top $link-transition,
			bottom $link-transition;

		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.boxes__item__description {
	padding-inline: var(--paragraph-break);
	padding-block: calc(var(--paragraph-break) * 2);

	@include screen($bp560) {
		padding: 3vw;
	}

	@include screen($bp1200) {
		padding-inline: var(--paragraph-break);
		padding-block: calc(var(--paragraph-break) * 2);
	}

	@include screen($bp1400) {
		font-size: var(--h4-font-size);
	}

	@include hover-enabled($bp1200) {
		flex: 1 1 auto;
		line-height: 1.2;
		padding: var(--paragraph-break);
		display: grid;
		place-items: center;
		opacity: 0;
		transition: none;
		// animation: fadeOutDescription $link-transition-duration both;
	}

	p:last-child {
		margin-bottom: 0;
	}
}

.boxes__item__button {
	margin-top: auto;

	@include hover-enabled($bp1200) {
		height: var(--box-title-height);
		margin-top: 0;
		flex: 0 0 auto;
	}
}


/* Fade in up */
@keyframes fadeInWhiteBackground {
    to {
        background-color: $color-lightblue;
    }
}

/* Fade in up */
@keyframes fadeOutDescription {
	from {
		opacity: 1;
	}
    to {
        opacity: 0;
    }
}

@keyframes fadeInDescription {
	from {
		opacity: 0;
	}
    to {
        opacity: 1;
    }
}
