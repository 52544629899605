@use "../../00-settings" as *;

.date-object__date {
    background-color: $color-01;
    color: $color-white;
    max-width: 80px;
    text-align: center;
    font-family: 'DIN Next W01', sans-serif;
}
.date-object__date__month {
    background-color: $color-00-dark;
    padding: 3px 10px 0;
    font-size: 1.4rem;
    text-transform: uppercase;
}
.date-object__date__day {
    padding: 6px 10px 3px;
    font-size: 3.4rem;
    line-height: 3.4rem;
}
