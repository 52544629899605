
@use "../../00-settings" as *;
@use "../../02-components/icons/_icon-circle-list.scss" as *;
@use "../../02-components/icons/_icomoon.scss" as *;

.panel {
    /* ---- ULs icon circle styles ---- */
    // Style ULs using the icon circle styles with tick
    div:where(.icon-circle-list) ul {
        @include icon-circle-list;
        margin-top: 0;
    }

    div:where(.icon-circle-list) li {
        position: relative;
        display: grid;
        grid-auto-flow: column;
        column-gap: 0.5em;
        align-items: center;
        justify-content: start;
        line-height: 1.15;
        margin-bottom: 12px;
    }

    div:where(.icon-circle-list) li:before {
        text-decoration: none;
        color: inherit;
        position: relative;
        white-space: nowrap;
    }

    div:where(.icon-circle-list) li:before {
        content: "\e904";
        @include icomoon-icon;
        @include circle-icon;

        // Reduce largest size to match reduced size body text
        @include screen($bp992) {
            height: 28px;
            width: 28px;
            font-size: 22px;
            line-height: 1.3;
        }
    }
}

@include screen($bp992) {
    .panel {
        font-size: 1.6rem;
    }
}

.panel__container {
    display: grid;

    @include screen($bp992) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: [header] auto [content] 1fr [ctas] auto [end];
        column-gap: calc(var(--section) / 2);
    }
}

.panel__header {
    margin-bottom: calc(var(--section) / 2);

    @include screen($bp992) {
        grid-column: span 2;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.panel__title {
    margin-bottom: 0;
    text-align: center;
}

.panel__tagline {
    margin-top: 0;
    text-align: center;
}

.panel__introduction {
    line-height: 1.2;
    margin-bottom: 25px;
    font-family: $alternative-font-face-01;
    font-size: var(--large-font-size);
    color: $color-00;
    text-align: center;
}

.panel__copy {
    .panel--media-right & {
        @include screen($bp992) {
            grid-column: 1;
            grid-row: content;
        }
    }

    .panel--media-left & {
        @include screen($bp992) {
            grid-column: 2;
            grid-row: content;
        }
    }

    &.full-width {
        @include screen($bp992) {
            grid-column: 1 / 3;
            text-align: center;
        }
    }

    > *:first-child {
        margin-top: 0;
    }
}

.panel__media {
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;

    @include screen($bp992) {
        margin-bottom: 0;
    }

    .panel--media-right & {
        @include screen($bp992) {
            grid-column: 2;
            grid-row-start: content;
        }
    }

    .panel--media-left & {
        @include screen($bp992) {
            grid-column: 1;
            grid-row-start: content;
        }
    }
}

.panel__image {
    width: 100%;

    img {
        width: 100%;
    }
}

.panel__video {
    display: block;
    aspect-ratio: 16/9;
    object-fit: cover;
}

.panel__ctas {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;

    @include screen($bp768) {
        text-align: center;
        display: flex;
        justify-content: center;
        gap: 1em;
    }

    @include screen($bp992) {
        margin-top: 25px;
    }

    @include screen($bp992) {
        grid-column: span 2;
    }

    a {
        flex: 0 1 auto;

        @include screen($bp560) {
            min-width: max(200px, 30%);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
