@use "../../00-settings" as *;
@use "../../01-base/typography/reverse-text-mixins" as *;
@use "../../02-components/container/_container" as *;

/* ---- Home banner ---- */
.hero-banner {
	padding: 0;
	background-color: $color-00;
	@include reverse-text;

	@include screen($bp768) {
		width: var(--edge-container);
		margin-inline: auto;
		margin-block: var(--edge-container-gutter);
	}

	@include screen($bp1200) {
		margin-bottom: $spacing;
	}

	@include screen($bp1920) {
		margin-top: 32px;
	}

    &:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(../images/texture-dots.svg);
        opacity: 0.1;
        background-size: cover;
		z-index: 0;
    }

	.splide__arrow {
		@include screen($bp767, 'max') {
			top: calc(((var(--edge-container) * 0.5) / 2) - ((var(--h1-font-size) + $spacing*4) / 2));
		}
	}
}

.hero-banner__item {

}

.hero-banner__copy {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	z-index: 1;
	padding-inline: var(--default-container-gutter);
	padding-block: var(--section);

	@include screen($bp768) {
		padding-inline: var(--edge-container-gutter);
		padding-bottom: var(--edge-container-gutter);
		padding-top: 0;
		height: calc(var(--edge-container) * 0.5);
	}

	@include screen($bp1200) {
		height: calc(var(--edge-container) * 0.4);
	}

	@include screen($bp1240) {
		padding-inline: calc(var(--default-container-gutter) - var(--edge-container-gutter));
		padding-bottom: calc((var(--default-container-gutter) - var(--edge-container-gutter)) / 2);
	}
}

.hero-banner__heading {
	font-size: var(--giga-font-size);
	font-family: $alternative-font-01;
	@include font-set('alternative-bold');
	line-height: 1;
	margin-top: 0;
}

.hero-banner__subtitle {
	font-size: var(--h2-font-size);
	@include font-set('accent');
	text-transform: uppercase;
	line-height: 1;
}

.hero-banner__description {
	font-size: var(--body-font-size);

	@include screen($bp768) {
		text-wrap: balance;
	}

	@include screen($bp1200) {
		max-width: calc(var(--default-container) * 0.75);
	}
}

.hero-banner__img {
	display: block;
	width: 100%;
	height: calc(var(--edge-container) * 0.5);

	@include screen($bp768) {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
		height: auto;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&:before,
	&:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba($color-00-dark, 0.15), rgba($color-00-dark, 0.9));
        background-size: cover;
    }

	&:before {
		mix-blend-mode: multiply;
	}

	&:after {
		background: linear-gradient(to bottom, rgba($color-00-dark, 0.15), rgba($color-00-dark, 0.5));
	}
}

.hero-banner__button {
	display: inline-block;
	align-self: flex-start;
}


/* ---- Home about ---- */
.section--home-about__buttons {
	margin-top: 80px;
}


/* BP 1 */
@media screen and (max-width: 1240px) {
	.section--home-about__buttons {
		margin-top: 50px;
	}
}

/* BP 2 */
@media screen and (max-width: 1000px) {
	.section--home-about__buttons {
		margin-top: 30px;
	}
}


/* BP 3 */
@media screen and (max-width: 760px) {
	.section--home-about__buttons {
		margin-top: 20px;
	}}

